// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import { Component } from 'src/helpers/Component';
import { useEffect, useRef } from 'react';

export type MetaLocatorProps =
  Feature.EnterpriseWeb.AndersenWindows.Components.General.MetaLocator.MetaLocator;

const MetaLocator = (props: MetaLocatorProps) => {
  const itemId = props.fields?.metaLocatorItemId?.value;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.fields?.metaLocatorScript?.value) {
      try {
        const fragment = document
          .createRange()
          .createContextualFragment(props.fields?.metaLocatorScript?.value);
        divRef.current?.append(fragment);
      } catch (error) {
        console.error('Error creating fragment:', error);
      }
    }
    // Suggested deps are coming from layout service. We can ignore useEffect warning.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!itemId) {
    return <></>;
  }

  return (
    <Component variant="full" dataComponent="general/metaLocator" {...props}>
      <div className="col-span-12">
        <div data-metalocator="locator" data-metalocator-itemid={itemId}></div>
        <div className="body-copy col-span-12 [&_*]:max-w-full" ref={divRef}></div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<MetaLocatorProps>(MetaLocator);
