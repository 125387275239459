import GenericCard from 'components/card/GenericCard/GenericCard';
import { Key, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Headline } from 'src/helpers/Headline';

interface cardVariants {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardVariants: any;
  typeVariant: string;
}

const CardHelper = (props: cardVariants) => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: `ty_impression_${props?.typeVariant}`,
        form_name: 'Request A Quote',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props?.cardVariants?.length > 0) {
      removeSidebar();
    }
  }, [props.cardVariants]);
  const removeSidebar = () => {
    const sidebar = document.querySelector(
      '.col-span-12.ml\\:col-span-4.ml\\:-mr-s.bg-light-gray.flex.flex-col.justify-start.order-last.ml\\:order-none'
    );
    const adjustWidth = document.querySelector(
      '.col-span-12.ml\\:col-span-8.pt-m.ml\\:pt-0.ml\\:grid.ml\\:grid-cols-6.ml\\:gap-s.middle.bg-white.-mt-s.ml\\:mt-0'
    );
    if (sidebar) {
      sidebar.remove();
    }

    if (adjustWidth) {
      adjustWidth.classList.remove('ml:col-span-8');
      adjustWidth.classList.add('ml:col-span-12');
    }
  };

  return (
    <>
      <div className="col-span-12">
        <Headline
          classes="text-theme-text text-center text-sm-m font-heavy mb-s"
          fields={{
            headlineText: {
              value: 'In the meantime, here are some topics to explore!',
            },
          }}
        />
      </div>
      {props.cardVariants.map((card: cardVariants, index: Key | null | undefined) => {
        return (
          <div key={index} className="col-span-12 bg-light-gray p-6 md:col-span-4">
            <GenericCard {...card} />
          </div>
        );
      })}
    </>
  );
};

export default CardHelper;
