// Global
import { useTheme } from 'lib/context/ThemeContext';
import {
  ComponentRendering,
  LinkField,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import { Project } from 'src/.generated/Project.AndersenCorporation.model';
import Component from 'src/helpers/Component/Component';
import { ArticleTagsTheme } from './ArticleTags.theme';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { getEnum } from 'lib/utils/get-enum';
export type ArticleTagsProps =
  Feature.EnterpriseWeb.Enterprise.Components.Pages.ArticleTags.ArticleTags & {
    rendering: ComponentRendering;
  };
type Tag = {
  facetId?: string;
  facetUid: string;
  value: string;
  dependsOn?: string;
  dependencyValues?: Tag[];
};
const ArticleTags = (props: ArticleTagsProps): JSX.Element => {
  const { themeData } = useTheme(ArticleTagsTheme);
  const context = useSitecoreContext();
  const page = context.sitecoreContext
    .route as Project.AndersenCorporation.AndersenWindows.Pages.ArticlePage;
  if (!page) {
    return <></>;
  }
  const mappedTags: Tag[] = [];
  const articlePublicTags = page.fields
    ?.articlePublicTags as unknown as Feature.EnterpriseWeb.Enterprise.Data.Search.FacetTag[];
  const facetField = props?.fields?.facetId?.value || 'tags';

  // Map the page tags
  if (page.fields != undefined) {
    const pageFields = page.fields as Record<string, unknown>;
    const fieldMappings = props.fields
      .children as Feature.EnterpriseWeb.Enterprise.Components.Pages.ArticleTags.FieldFacetMapping[];

    if (fieldMappings.length < 1) {
      articlePublicTags.forEach((tag) => {
        mappedTags.push({ facetUid: facetField, value: tag.fields?.title?.value ?? '' });
      });
    }
    fieldMappings.forEach((map) => {
      const pageField = getEnum<string>(map.fields?.field);
      if (!pageField || !pageFields.hasOwnProperty(pageField)) {
        return;
      }
      const facetField = pageFields[
        pageField
      ] as Feature.EnterpriseWeb.Enterprise.Data.Search.FacetTag[];
      const values = facetField.map((item) => {
        return item.fields?.title?.value;
      });
      const facet = map.fields?.facet as Feature.EnterpriseWeb.Enterprise.Elements.Search.Facet;
      const facetUid = facet?.fields.uniqueIdentifier.value;
      if (!facetUid || !values) {
        return;
      }
      values.forEach((item) => {
        if (item) {
          mappedTags.push({
            facetUid,
            value: item,
            facetId: facet.id,
            dependsOn: facet.fields.dependsOn?.id,
          });
        }
      });
    });
  } // Resolve dependencies
  mappedTags.forEach((tag) => {
    if (!tag.dependsOn) {
      return;
    }
    const dependencyValues: Tag[] = [];
    let dependsOn: string | undefined = tag.dependsOn;
    while (dependsOn != undefined) {
      const dependency = mappedTags.find((_) => _.facetId && _.facetId == dependsOn);
      if (!dependency) {
        return;
      }
      dependencyValues.push({ facetUid: dependency.facetUid, value: dependency.value });
      dependsOn = dependency.dependsOn;
    }
    tag.dependencyValues = dependencyValues;
  });
  if (mappedTags.length < 1) {
    return <></>;
  }
  const linkField = props?.fields?.tagsLink as LinkField;
  return (
    <Component variant="lg" dataComponent="pages/articletags" {...props}>
      <div className={themeData.classes.tagsWrapper}>
        {mappedTags.map((tag) => {
          const params = [`f:${tag.facetUid}=[${tag.value}]`];
          tag.dependencyValues?.forEach((_) => params.push(`f:${_.facetUid}=[${_.value}]`));
          return (
            <>
              {linkField?.value?.href && (
                <a href={`${linkField?.value?.href}#${params.join('&')}`} key={tag.facetUid}>
                  <div className={themeData.classes.tag}>{tag.value}</div>{' '}
                </a>
              )}
              {!linkField?.value?.href && (
                <div key={tag.facetUid} className={themeData.classes.tag}>
                  {tag.value}
                </div>
              )}
            </>
          );
        })}
      </div>
    </Component>
  );
};
export default withDatasourceCheck()<ArticleTagsProps>(ArticleTags);
